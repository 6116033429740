.link,
.link:link,
.link:visited {
  cursor: pointer;
  color: #0063fb;
  text-decoration: none;
}
.link:hover,
.link:focus,
.link:active {
  color: #0063fb;
  text-decoration: underline;
  outline: none;
}
.link--show-history:visited,
.link--show-history a:visited,
.link--show-history:visited:hover,
.link--show-history a:visited:hover {
  color: #767676;
}
.link--block:hover,
.link--block a:hover,
.link--block:active,
.link--block a:active,
.link--block:focus,
.link--block a:focus {
  text-decoration: none;
}
.link--dark,
.link--dark a,
.link--dark:link,
.link--dark a:link,
.link--dark:visited,
.link--dark a:visited,
.link--dark:hover,
.link--dark a:hover,
.link--dark:focus,
.link--dark a:focus,
.link--dark:active,
.link--dark a:active {
  color: #474445;
}
/*


        Link styling & variants


*/

/*# sourceMappingURL=link.css.map */